import 'core-js';
import '@babel/polyfill';
import React from 'react';
import { render } from 'react-dom';
import i18next from '@assetsJsModule/i18next';

import { SERVICE_AGREEMENT_NAMESPACE } from './config/constants';
import CookieConsentDialog from './components/CookieConsentDialog';
import { loggerFactory } from '../../../../../../../assets/js/module/logger';

const logger = loggerFactory('cookie-consent');

const mountDialogComponent = () => {
    render(
        <CookieConsentDialog />,
        document.getElementById('cookie-consent-react-root'),
    );
};

(async () => {
    try {
        await i18next.loadNamespaces(SERVICE_AGREEMENT_NAMESPACE);
        window.addEventListener('load', mountDialogComponent);
    } catch (error) {
        logger.error('Failed to initialize Cookie Consent Dialog.', error);
    }
})();
