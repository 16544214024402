export default () => {
    let locale = null;
    const html = document.getElementsByTagName('html');

    if (html.length > 0 && html[0].hasAttribute('lang')) {
        locale = html[0].getAttribute('lang');
    }

    if (locale === '') {
        document.querySelectorAll('[lang]').forEach((element) => {
            const lang = element.getAttribute('lang');
            if (lang && locale === '') {
                locale = lang;
            }
        });
    }

    if (locale === '') {
        document.querySelectorAll('[data-ng-app]').forEach((element) => {
            const lang = element.getAttribute('lang');
            if (lang) {
                locale = lang;
            }
        });
    }

    return locale;
}
