import React from 'react';
import PropTypes from 'prop-types';
import Tabs from 'react-bootstrap/lib/Tabs';
import Tab from 'react-bootstrap/lib/Tab';

import { CATEGORY_NECESSARY, CATEGORY_STATISTICS, CATEGORY_MARKETING } from '../../config/constants';
import useServiceAgreementTranslations from '../../service/useServiceAgreementTranslations';
import CookiesTable from '../CookiesTable';
import {
    getMarketingCookiesData,
    getNecessaryCookiesData,
    getStatisticsCookiesData,
} from '../CookiesTable/tableData';

const CookieTabs = ({ canShowCategory }) => {
    const { t } = useServiceAgreementTranslations();
    return (
        <React.Fragment>
            <Tabs defaultActiveKey={CATEGORY_NECESSARY} id="cookies-category-tabpanel">
                <Tab
                    eventKey={CATEGORY_NECESSARY}
                    title={(
                        <p className="body-text">
                            {t('service_agreement_cookies.category.necessary')}
                        </p>
                    )}
                >
                    <p className="body-text tab-description">
                        {t('service_agreement_cookies.category.necessary.body')}
                    </p>
                    <CookiesTable data={getNecessaryCookiesData()} />
                </Tab>
                {canShowCategory(CATEGORY_STATISTICS) && (
                    <Tab
                        eventKey={CATEGORY_STATISTICS}
                        title={(
                            <p className="body-text">
                                {t('service_agreement_cookies.category.statistics')}
                            </p>
                        )}
                    >
                        <p className="body-text tab-description">
                            {t('service_agreement_cookies.category.statistics.body')}
                        </p>
                        <CookiesTable data={getStatisticsCookiesData()} />
                    </Tab>
                )}
                {canShowCategory(CATEGORY_MARKETING) && (
                    <Tab
                        eventKey={CATEGORY_MARKETING}
                        title={(
                            <p className="body-text">
                                {t('service_agreement_cookies.category.marketing')}
                            </p>
                        )}
                    >
                        <p className="body-text tab-description">
                            {t('service_agreement_cookies.category.marketing.body')}
                        </p>
                        <CookiesTable data={getMarketingCookiesData()} />
                    </Tab>
                )}
            </Tabs>
        </React.Fragment>
    );
};

CookieTabs.propTypes = {
    canShowCategory: PropTypes.func,
};

CookieTabs.defaultProps = {
    canShowCategory: () => true,
};

export default React.memo(CookieTabs);
