import i18next from 'i18next';
import durationFormatter from '../../../service/durationFormatter';
import { SERVICE_AGREEMENT_NAMESPACE } from '../../../config/constants';
import getCurrentLocale from '../../../service/getCurrentLocale';

const locale = getCurrentLocale();
const getTranslatedDuration = durationFormatter(locale);
const t = i18next.getFixedT(null, SERVICE_AGREEMENT_NAMESPACE);

const getStatisticsCookiesData = () => [
    {
        name: '_dc_gtm_UA-*',
        provider: '.tracking.paysera.com',
        expiry: getTranslatedDuration(1, 'minute'),
        purpose: t('service_agreement_cookies.table.google_tag.purpose'),
    },
    {
        name: '_fbp',
        provider: '.paysera.com',
        expiry: getTranslatedDuration(3, 'months'),
        purpose: t('service_agreement_cookies.table.fb.purpose'),
    },
    {
        name: '_ga',
        provider: '.tracking.paysera.com',
        expiry: getTranslatedDuration(2, 'years'),
        purpose: t('service_agreement_cookies.table.unique_id.purpose'),
    },
    {
        name: '_gcl_au',
        provider: '.paysera.com',
        expiry: getTranslatedDuration(3, 'months'),
        purpose: t('service_agreement_cookies.table.conversion.purpose'),
    },
    {
        name: '_gid',
        provider: '.tracking.paysera.com',
        expiry: getTranslatedDuration(1, 'day'),
        purpose: t('service_agreement_cookies.table.usage_id.purpose'),
    },
    {
        name: '_hjAbsoluteSessionInProgress',
        provider: '.paysera.com',
        expiry: getTranslatedDuration(1, 'hour'),
        purpose: t('service_agreement_cookies.table.hotjar_pageview.purpose'),
    },
    {
        name: '_hjid',
        provider: '.paysera.com',
        expiry: getTranslatedDuration(1, 'year'),
        purpose: t('service_agreement_cookies.table.hotjar_id.purpose'),
    },
    {
        name: '_hjIncludedInPageviewSample',
        provider: 'tracking.paysera.com',
        expiry: getTranslatedDuration(15, 'minutes'),
        purpose: t('service_agreement_cookies.table.hotjar_sampling.purpose'),
    },
];

export default getStatisticsCookiesData;
