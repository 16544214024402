import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import detectLanguage from './detectLanguage';
import loadTranslations from './loadTranslations';

i18next
    .use(initReactI18next)
    .use({
        type: 'languageDetector',
        async: false,
        init: () => null,
        detect: detectLanguage,
        cacheUserLanguage: () => null,
    })
    .use({
        type: 'backend',
        init: () => null,
        read: loadTranslations,
    })
    .init({
        debug: false,
        fallbackLng: 'en',
        keySeparator: '',
        initImmediate: false,
        interpolation: {
            prefix: '%',
            suffix: '%',
        },
    });

export default i18next;
