import { loggerFactory } from '../../../../../../../../assets/js/module/logger';
import { CONSENT_COOKIE_KEY } from '../config/constants';

const logger = loggerFactory('cookie-consent');

const hasCookieCategoryConsent = (category) => {
    const cookieConsentKeyValue = document.cookie
        .split('; ')
        .find(cookie => cookie.startsWith(CONSENT_COOKIE_KEY));

    if (cookieConsentKeyValue === undefined) {
        return false;
    }

    const consentCookieValue = cookieConsentKeyValue.split('=')[1];

    try {
        const parsedConsentCookieValue = JSON.parse(decodeURIComponent(consentCookieValue));

        return Array.isArray(parsedConsentCookieValue) ? parsedConsentCookieValue.includes(category) : false;
    } catch (error) {
        logger.error(`Failed to parse Cookie '${CONSENT_COOKIE_KEY}'.`, { consentCookieValue, error });
        return false;
    }
};

export default hasCookieCategoryConsent;
