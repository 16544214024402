import React from 'react';
import CookieAgreementCard from '../CookieAgreementCard';
import isConsentRelevant from '../../service/isConsentRelevant';

const CookieConsentDialog = () => {
    if (!isConsentRelevant()) {
        return null;
    }
    return <CookieAgreementCard />;
};

export default React.memo(CookieConsentDialog);
