import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Panel from 'react-bootstrap/lib/Panel';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import Button from 'react-bootstrap/lib/Button';

import useServiceAgreementTranslations from '../../service/useServiceAgreementTranslations';
import addAllCategoriesConsent from '../../service/addAllCategoriesConsent';
import addNecessaryCategoriesConsent from '../../service/addNecessaryCategoriesConsent';
import canShowCategory from '../../service/canShowCategory';
import Tabs from '../Tabs';
import CookieConsentCategorySelection from '../CookieConsentCategorySelection';

const noop = () => {};

const Content = ({ closeAgreementCard }) => {
    const { t } = useServiceAgreementTranslations();
    const [collapseOpen, setCollapseOpen] = useState(false);

    const handleAcceptRecommended = useCallback(
        () => {
            addAllCategoriesConsent();
            closeAgreementCard();
        },
        [],
    );

    const handleAcceptOnlyNecessary = useCallback(
        () => {
            addNecessaryCategoriesConsent();
            closeAgreementCard();
        },
        [],
    );

    const toggleCollapse = () => {
        setCollapseOpen(!collapseOpen);
    };

    return (
        <div className="cookie-consent-dialog-container">
            <Row>
                <Col xs={12}>
                    <p className="body-text vertical-gap-md">
                        {t('service_agreement_cookies.body')}
                    </p>
                </Col>
                <Col xs={12}>
                    <Row className="cookie-consent-buttons">
                        <Col sm={4} xs={12}>
                            <button
                                type="button"
                                className="btn btn-link customize-button"
                                onClick={toggleCollapse}
                            >
                                {t('service_agreement_cookies.button.customise')}
                                {!collapseOpen
                                    ? <Glyphicon className="collapse-icon" glyph="chevron-down" onClick={toggleCollapse} />
                                    : <Glyphicon className="collapse-icon" glyph="chevron-up" onClick={toggleCollapse} />
                                }
                            </button>
                        </Col>
                        <Col sm={4} xs={12}>
                            <button
                                type="button"
                                className="btn btn-link customize-button"
                                onClick={handleAcceptOnlyNecessary}
                            >
                                {t('service_agreement_cookies.button.only_necessary')}
                            </button>
                        </Col>
                        <Col sm={4} xs={12}>
                            <Button
                                className="customize-button"
                                bsStyle="primary"
                                onClick={handleAcceptRecommended}
                            >
                                {t('service_agreement_cookies.button.accept_recommended')}
                            </Button>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12}>
                    <Panel expanded={collapseOpen} className="panel-borderless" onToggle={noop}>
                        <Panel.Collapse>
                            <CookieConsentCategorySelection
                                canShowCategory={canShowCategory}
                                closeAgreementCard={closeAgreementCard}
                            />
                            <Tabs canShowCategory={canShowCategory} />
                        </Panel.Collapse>
                    </Panel>
                </Col>
            </Row>
        </div>
    );
};

Content.propTypes = {
    closeAgreementCard: PropTypes.func.isRequired,
};

export default React.memo(Content);
