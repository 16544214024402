import i18next from 'i18next';
import durationFormatter from '../../../service/durationFormatter';
import { SERVICE_AGREEMENT_NAMESPACE, CONSENT_COOKIE_KEY } from '../../../config/constants';
import getCurrentLocale from '../../../service/getCurrentLocale';

const locale = getCurrentLocale();
const getTranslatedDuration = durationFormatter(locale);
const t = i18next.getFixedT(null, SERVICE_AGREEMENT_NAMESPACE);

const getNecessaryCookiesData = () => [
    {
        name: CONSENT_COOKIE_KEY,
        provider: window.location.host,
        expiry: getTranslatedDuration(1, 'year'),
        purpose: t('service_agreement_cookies.table.consent.purpose'),
    },
];

export default getNecessaryCookiesData;
