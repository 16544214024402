import { logger, SentryProvider, ConsoleProvider } from 'paysera-logger';
import Raven from 'raven-js';

Raven.config(
    process.env.PUBLIC_SENTRY_DSN,
    {
        whitelistUrls: ["paysera.", "webpack:"],
        allowDuplicates: false,
    }
).install().noConflict();

window.Raven = Raven;

// default logger provider
if (process.env.NODE_ENV === 'production') {
    logger.addProvider(new SentryProvider());
} else {
    logger.addProvider(new ConsoleProvider());
}

export default Raven;
