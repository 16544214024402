const SERVICE_AGREEMENT_NAMESPACE = 'PayseraServiceAgreementBundle';
const CONSENT_COOKIE_KEY = 'CookieConsent';
const CATEGORY_NECESSARY = 'necessary';
const CATEGORY_STATISTICS = 'statistics';
const CATEGORY_MARKETING = 'marketing';

export {
    SERVICE_AGREEMENT_NAMESPACE,
    CONSENT_COOKIE_KEY,
    CATEGORY_NECESSARY,
    CATEGORY_STATISTICS,
    CATEGORY_MARKETING,
};
