import {
    Logger as PayseraLogger,
    SentryProvider,
    ConsoleProvider
} from 'paysera-logger';

export default function (loggerName = null) {
    const logger = new PayseraLogger();

    if (process.env.NODE_ENV === 'production') {
        logger.addProvider(new SentryProvider(loggerName));
    } else {
        logger.addProvider(new ConsoleProvider());
    }

    return logger;
}
