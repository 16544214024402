import React from 'react';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/lib/Table';

const CookiesTable = ({ data }) => (
    <Table responsive>
        <thead>
            <tr>
                <th>Name</th>
                <th>Provider</th>
                <th>Expiry</th>
                <th>Purpose</th>
            </tr>
        </thead>
        <tbody>
            {data.map(row => (
                <tr key={row.name}>
                    <td>{row.name}</td>
                    <td>{row.provider}</td>
                    <td>{row.expiry}</td>
                    <td>{row.purpose}</td>
                </tr>
            ))}
        </tbody>
    </Table>
);

CookiesTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        expiry: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        provider: PropTypes.string.isRequired,
        purpose: PropTypes.string.isRequired,
    })).isRequired,
};

export default CookiesTable;
