/**
 * Parses URL and returns country code
 */
const getCountryFromUrl = () => {
    const [, , locale] = window.location.pathname.split('/');
    const [, countryCode] = locale.split('-');
    return countryCode;
};

export default getCountryFromUrl;
