import {
    CATEGORY_NECESSARY,
    CATEGORY_STATISTICS,
    CATEGORY_MARKETING,
} from '../config/constants';
import addSelectedCategoriesConsent from './addSelectedCategoriesConsent';
import canShowCategory from './canShowCategory';

const addAllCategoriesConsent = () => {
    let categories = [
        CATEGORY_NECESSARY,
        CATEGORY_STATISTICS,
        CATEGORY_MARKETING,
    ];
    categories = categories.filter(canShowCategory);

    addSelectedCategoriesConsent(categories);
};

export default addAllCategoriesConsent;
