import { loggerFactory } from '@assetsJsModule/logger';

import librariesNamespaceMap from './config/librariesNamespaceMap.json';
import mapNamespaceTranslations from './mapNamespaceTranslations';

const logger = loggerFactory('i18next-loadTranslations');

export default async (language, namespace, callback) => {
    let domain = namespace;
    if (typeof librariesNamespaceMap[namespace] !== 'undefined') {
        domain = librariesNamespaceMap[namespace];
    }

    if (domain === 'translation') {
        // empty translations for default domain
        callback(null, {});
        return;
    }

    try {
        const listOfDomains = Array.isArray(domain) ? domain : [domain];
        const translations = listOfDomains.map(domainItem => new Promise(async (resolve) => {
            try {
                const domainTranslations = await import(`@translations/${domainItem}/${language}.json`);

                resolve(domainTranslations.translations[language][domainItem]);
            } catch (error) {
                logger.error('Failed to load translations', { domain, language, error });
                callback(null, {});
            }
        }));

        const mergedTranslations = (await Promise.all(translations))
            .reduce(
                (previousValue, currentValue) => ({ ...previousValue, ...currentValue }),
                {},
            );

        callback(null, mapNamespaceTranslations(namespace, mergedTranslations));
    } catch (error) {
        logger.error('Failed to load translations', { domain, language, error });
        callback(null, {});
    }
};
