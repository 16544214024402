import React, { useCallback, useEffect, useState } from 'react';
import Panel from 'react-bootstrap/lib/Panel';
import hasCookieConsent from '../../service/hasCookieConsent';
import Content from '../Content';

const CookieAgreementCard = () => {
    const [open, setOpen] = useState(false);
    const closeCard = useCallback(() => setOpen(false), []);

    useEffect(() => setOpen(!hasCookieConsent()), []);

    if (!open) {
        return null;
    }

    return (
        <div className="cookie-consent-dialog">
            <Content closeAgreementCard={closeCard} />
        </div>
    );
};

export default React.memo(CookieAgreementCard);
