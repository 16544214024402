import moment from 'moment';
import { CONSENT_COOKIE_KEY } from '../config/constants';
import { loggerFactory } from '../../../../../../../../assets/js/module/logger';

const logger = loggerFactory('cookie-consent');

const makeConsentCookie = (categories = []) => {
    if (!Array.isArray(categories)) {
        logger.error('Invalid consent cookie value provided.', categories);
        return '';
    }

    const encodedCategories = encodeURIComponent(JSON.stringify(categories));
    const date = moment().add(1, 'year').toString();

    return `${CONSENT_COOKIE_KEY}=${encodedCategories}; expires=${date}; path=/; Secure; SameSite=Lax`;
};

export default makeConsentCookie;
