import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/lib/Button';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Checkbox from 'react-bootstrap/lib/Checkbox';
import {
    CATEGORY_MARKETING,
    CATEGORY_NECESSARY,
    CATEGORY_STATISTICS,
} from '../../config/constants';


import useServiceAgreementTranslations from '../../service/useServiceAgreementTranslations';
import hasCookieCategoryConsent from '../../service/hasCookieCategoryConsent';
import addSelectedCategoriesConsent from '../../service/addSelectedCategoriesConsent';

const CookieConsentCategorySelection = ({ closeAgreementCard, canShowCategory }) => {
    const { t } = useServiceAgreementTranslations();
    const [checkboxValues, setCheckboxValues] = useState({
        [CATEGORY_NECESSARY]: true,
        [CATEGORY_STATISTICS]: hasCookieCategoryConsent(CATEGORY_STATISTICS),
        [CATEGORY_MARKETING]: hasCookieCategoryConsent(CATEGORY_MARKETING),
    });

    const handleAcceptSelected = useCallback(
        () => {
            const categories = [];
            Object.entries(checkboxValues).forEach(([categoryKey, checked]) => {
                if (checked) {
                    categories.push(categoryKey);
                }
            });
            addSelectedCategoriesConsent(categories);
            closeAgreementCard();
        },
        [checkboxValues],
    );

    const handleCheckboxChange = useCallback(
        (event) => {
            const checkboxName = event.target.name;
            const currentValue = checkboxValues[checkboxName];

            if (currentValue === undefined) {
                return;
            }

            setCheckboxValues({
                ...checkboxValues,
                [checkboxName]: event.target.checked,
            });
        },
        [checkboxValues],
    );

    return (
        <Row className="selection-controls-row">
            <Col xs={7} sm={8} md={9}>
                <FormGroup>
                    <Checkbox inline checked disabled name={CATEGORY_NECESSARY}>
                        <p className="body-text">
                            {t('service_agreement_cookies.category.necessary')}
                        </p>
                    </Checkbox>
                    {' '}
                    {canShowCategory(CATEGORY_STATISTICS) && (
                        <Checkbox
                            inline
                            name={CATEGORY_STATISTICS}
                            checked={checkboxValues[CATEGORY_STATISTICS]}
                            onChange={event => handleCheckboxChange(event)}
                        >
                            <p className="body-text">
                                {t('service_agreement_cookies.category.statistics')}
                            </p>
                        </Checkbox>
                    )}
                    {' '}
                    {canShowCategory(CATEGORY_MARKETING) && (
                        <Checkbox
                            inline
                            name={CATEGORY_MARKETING}
                            checked={checkboxValues[CATEGORY_MARKETING]}
                            onChange={event => handleCheckboxChange(event)}
                        >
                            <p className="body-text">
                                {t('service_agreement_cookies.category.marketing')}
                            </p>
                        </Checkbox>
                    )}
                </FormGroup>
            </Col>
            <Col xs={5} sm={4} md={3}>
                <Button
                    onClick={handleAcceptSelected}
                    data-testid="accept-selection-button"
                    bsSize="small"
                    className="accept-section-button pull-right"
                >
                    {t('service_agreement_cookies.button.accept_selection')}
                </Button>
            </Col>
        </Row>
    );
};

CookieConsentCategorySelection.propTypes = {
    closeAgreementCard: PropTypes.func.isRequired,
    canShowCategory: PropTypes.func,
};

CookieConsentCategorySelection.defaultProps = {
    canShowCategory: () => true,
};

export default React.memo(CookieConsentCategorySelection);
