import i18n from 'i18next';

const getMarketingCookiesData = () => [
    {
        name: '_dummy',
        provider: 'dummy.com',
        expiry: i18n.t('service_agreement_cookies.table.expiry.dummy'),
        purpose: i18n.t('service_agreement_cookies.table.purpose.dummy'),
    },
];

export default getMarketingCookiesData;
