import { CATEGORY_MARKETING } from './constants';

export const BLACKLISTED_CATEGORIES = [
    CATEGORY_MARKETING,
];

export const WHITELISTED_COUNTRIES = [
    'BG', // Bulgaria
    // 'ES', // Spain,
    'AL', // Albania,
];
