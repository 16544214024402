import isUndefined from 'lodash/isUndefined';

import translationsMap from './config/translationsMap.json';

export default (namespace, translations) => {
    if (isUndefined(translationsMap[namespace])) {
        return translations;
    }

    const mappedTranslations = { ...translations };
    Object.keys(translationsMap[namespace]).forEach((origTransKey) => {
        if (!isUndefined(translations[origTransKey])) {
            mappedTranslations[translationsMap[namespace][origTransKey]] = translations[origTransKey];
        }
    });

    return mappedTranslations;
};
